<template>
  <section
    class="presentation__agent-sticky animate__animated"
    :class="{ animate__fadeIn: rollup }"
    v-if="AgentData?.agent && rollup"
  >
    <div class="agent">
      <q-avatar size="32px">
        <img :src="AgentData?.agent.avatar_url" alt="img" />
      </q-avatar>
      <p class="agent-info-name">
        {{ AgentData.agent.last_name + ' ' + AgentData.agent.first_name }}
      </p>
    </div>
    <base-icon
      @click="PopUp.callMobilPopUp({ value: 'AgentContactsPopUp', position: 'bottom' })"
      name="send"
      size="24px"
      color="#7444AB"
    />
  </section>
</template>

<script setup lang="ts">
import { PresentationStore } from '~/store/PresentationStore'
import { PopUpStore } from '~/store/popUp'

const props = defineProps({
  isScoped: {
    required: false,
    type: Boolean,
  },
})

const PStore = PresentationStore()
const PopUp = PopUpStore()
const rollup = computed(() => props.isScoped)
const AgentData = computed(() => PStore.$state.tableObjsData)
</script>

<style scoped lang="scss">
.presentation {
  &__agent {
    &-sticky {
      width: 100vw;
      height: 52px;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      top: 0;
      border-bottom: 1px solid var(--slate-200);
      background: var(--white, #fff);
      z-index: 999;
      padding-top: 14px;
      padding-bottom: 14px;
      padding-left: 14px;
      padding-right: 16px;
      display: none;
      .agent {
        display: flex;
        align-items: center;
        gap: 12px;
        .agent-info-name {
          color: var(--slate-800);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
        }
      }
    }
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .presentation {
    &__agent {
      &-sticky {
        display: flex;
      }
    }
  }
}
</style>
